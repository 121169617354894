import React, { useEffect, useState } from 'react';
import styles from './AddOrEditPresentation.module.scss';
import { useForm } from 'react-hook-form';
/* eslint-disable no-useless-escape */
import {
  Divider,
  Box,
  TextField,
  styled,
  Select,
  MenuItem,
  useMediaQuery,
  Typography,
  useTheme,
} from '@mui/material';
import { AddIcon, openNotification, Image, ReloadIcon, TrashIcon, Button } from 'tt-ui-kit';
import { useDropzone } from 'react-dropzone';
import LinearProgress from '@mui/material/LinearProgress/LinearProgress';
import { v1 as uuidv1 } from 'uuid';
import axios from 'axios';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  GET_URL_FOR_PUBLIC_UPLOAD,
  CREATE_PRESENTATION,
  GET_PRESENTATION_BY_ID,
  UPDATE_PRESENTATION,
  GET_SECTIONS,
} from '../../../../../../../../api';
import { DropZone } from '../../../../../../../../components/dropZone';
import PartialLoader from '../../../../../../../../components/layout/PartialLoader';

const POSITIONS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export const MuiTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    height: '48px',
    borderRadius: '12px',
  },
  '& .MuiInputBase-input': {
    height: '48px',
    padding: '0 14px',
  },
  ".MuiInputLabel-root[data-shrink='false']": {
    transform: 'translate(14px, 13px) scale(1) !important',
  },
}));

const AddOrEditPresentation = ({
  presentationActionOrId,
  activeSection,
  setPresentationAction,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(901));
  const [fileToS3, setFileToS3] = useState(null);
  const [sections, setSections] = useState([]);
  const [previewFile, setPreviewFile] = useState({});
  const [coverFile, setCoverFile] = useState({});
  const [file, setFile] = useState({});
  const [fileUrl, setFileUrl] = useState('');
  const [addLink, setAddLink] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [getPublicUrl] = useMutation(GET_URL_FOR_PUBLIC_UPLOAD, {
    variables: { input: { fileToS3 } },
  });
  const [getPresentationById] = useLazyQuery(GET_PRESENTATION_BY_ID);
  const [createNewPresentation] = useMutation(CREATE_PRESENTATION);
  const [updatePresentation] = useMutation(UPDATE_PRESENTATION);
  const [getSections] = useLazyQuery(GET_SECTIONS);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (fileUrl || watch('link')) {
      clearErrors('file');
    }
    if (previewFile.fullName) {
      clearErrors('preview');
    }
  }, [fileUrl, watch('link'), previewFile]);

  useEffect(() => {
    (async () => {
      setPageLoading(true);
      const sectionsRes = await getSections({
        variables: {
          type: 'presentation',
        },
        fetchPolicy: 'network-only',
      });

      if (sectionsRes && sectionsRes.data && sectionsRes.data.showAllSections) {
        setSections(sectionsRes.data.showAllSections);
      }

      if (presentationActionOrId && presentationActionOrId !== 'add') {
        const response = await getPresentationById({
          variables: { id: presentationActionOrId },
          fetchPolicy: 'network-only',
        });
        if (response.data && response.data.showPresentationById) {
          const data = response.data.showPresentationById;
          setValue('name', data.name);
          setValue('section_id', data.section_id);
          setValue('position', data.position);
          setValue('keywords', data.keywords);
          setValue('link', data.link);
          setPreviewFile({ image: data.preview, fullName: data.preview.split('/').pop() });
          setFile({
            url: data.file,
            name: data.file_name.split('.').slice(-2).join('.'),
            extension: data.type,
            fullName: data.file_name,
          });
          setFileUrl(data.file);
          setAddLink(true);
        }
      }
      setPageLoading(false);
    })();
  }, [presentationActionOrId]);

  const convertFileSize = (bytes) => {
    const KB = 1024;
    const MB = KB * 1024;

    if (bytes >= MB) {
      return `${(bytes / MB).toFixed(2)} MB`;
    }
    return `${(bytes / KB).toFixed(2)} KB`;
  };

  const uploadFile = async (fileValue, type) => {
    try {
      const formatFile = fileValue?.name.substr(-4, 4);
      let filesName;
      if (formatFile === '.') {
        filesName = `${uuidv1()}.${fileValue?.name}${formatFile}`;
      } else {
        filesName = `${uuidv1()}.${fileValue?.name}`;
      }
      const path = `presentations/${type}`;
      const toUpload = { path: path, name: filesName };
      setFileToS3(toUpload);
      const { data } = await getPublicUrl({ variables: { input: toUpload } });
      setFileToS3(null);
      const urlToUpload = data?.getUrlForPublicUpload?.url;
      if (type === 'files') {
        const urlToView = `https://${data?.getUrlForPublicUpload?.download_url}`;
        setFileUrl(urlToView);
        setFile((prev) => ({ ...prev, fullName: filesName }));
      }
      if (type === 'preview') {
        setPreviewFile((prev) => ({ ...prev, fullName: filesName }));
      }
      await axios
        .put(urlToUpload, fileValue, {
          headers: {
            'Content-type': fileValue.type,
            'Access-Control-Allow-Origin': '*',
          },
          onUploadProgress: (progressEvent) => {
            if (type === 'preview') {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              setPreviewFile((current) => ({ ...current, progress: percentCompleted }));
            }
          },
        })
        .catch((err) => {
          if (type === 'preview') {
            setPreviewFile((current) => ({ ...current, error: true, progress: 0 }));
          }
          console.log('Error!', err);
        });
    } catch (error) {
      console.log('error !!!', error);
      openNotification({
        message: 'Error while uploading file.',
        type: 'error',
      });
    }
  };

  const onDropped = (files, type) => {
    if (files && files.length) {
      for (let i = 0; i < files.length; i++) {
        const reader = new FileReader();
        if (type === 'preview') {
          reader.onload = (e) => {
            setPreviewFile({
              image: e.target.result,
              progress: 0,
              error: false,
              file: files[i],
            });
          };
          uploadFile(files[i], type);
        }
        if (type === 'cover') {
          reader.onload = (e) => {
            setCoverFile({
              image: e.target.result,
              progress: 100,
              error: false,
              file: files[i],
            });
          };
        }
        if (type === 'files') {
          reader.onload = (e) => {
            const fileName = files[i].name;
            const fileExtension = fileName.split('.').pop();
            setFile({
              name: fileName,
              base64: e.target.result,
              url: fileUrl,
              extension: fileExtension,
              size: convertFileSize(files[i].size),
            });
          };
          uploadFile(files[i], type);
        }
        reader.readAsDataURL(files[i]);
      }
    }
  };

  const {
    acceptedFiles: previewAcceptedFiles,
    getRootProps: getPreviewRootProps,
    getInputProps: getPreviewInputProps,
  } = useDropzone({
    accept: {
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png'],
      'image/gif': ['.gif'],
    },
    multiple: false,
    onDrop: (files) => onDropped(files, 'preview'),
  });

  const {
    acceptedFiles: coverAcceptedFiles,
    getRootProps: getCoverRootProps,
    getInputProps: getCoverInputProps,
  } = useDropzone({
    accept: {
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png'],
      'image/gif': ['.gif'],
    },
    multiple: false,
    onDrop: (files) => onDropped(files, 'cover'),
  });

  function getFileType(fileToCheck) {
    const mimeType = fileToCheck.base64.match(/^data:(.*?);base64,/)[1];

    if (mimeType.startsWith('video/')) {
      return 'video';
    }
    if (mimeType.startsWith('image/')) {
      return 'image';
    }
    if (mimeType === 'application/pdf') {
      return 'pdf';
    }
    return 'other';
  }

  const onSubmit = async (data) => {
    if (!fileUrl && !watch('link')) {
      openNotification({
        message: 'You must feel all required fields',
        type: 'error',
      });
      setError('file', { type: 'custom', message: 'Add file or link' });
      return;
    }
    if (!previewFile.fullName) {
      setError('preview', { type: 'custom', message: 'Add preview photo' });
      return;
    }
    if (presentationActionOrId && presentationActionOrId !== 'add') {
      const updateInput = {
        ...data,
        id: presentationActionOrId,
        preview: previewFile.fullName || '',
        file: file.fullName || '',
      };
      if (file && file.base64) {
        updateInput.type = getFileType(file);
      } else if (data.link) {
        updateInput.type = 'link';
      }
      const response = await updatePresentation({ variables: { input: updateInput } });
      if (response && response.data && response.data.updatePresentation) {
        openNotification({
          message: 'Presentation updated successfully',
          type: 'success',
        });
        setPresentationAction('');
      }
    } else {
      // ToDo add size on inputData and save in backend
      const input = {
        ...data,
        preview: previewFile.fullName || '',
        file: file.fullName || '',
      };
      if (file && file.base64) {
        input.type = getFileType(file);
      } else if (data.link) {
        input.type = 'link';
      }
      const response = await createNewPresentation({ variables: { input: input } });
      if (response && response.data && response.data.createPresentation) {
        openNotification({
          message: 'Presentation created successfully',
          type: 'success',
        });
        setPresentationAction('');
      }
    }
  };

  const openPdfFile = () => {
    const newWindow = window.open(fileUrl, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  const deleteFile = (e) => {
    e.stopPropagation();
    setFile({});
    setFileUrl('');
  };

  return (
    <>
      {pageLoading ? (
        <PartialLoader loading={pageLoading} isLightContainer />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)} className={styles.pageContent}>
          <Box className={styles.form}>
            <Box className={styles.inputsBlock}>
              <MuiTextField
                label="Document name"
                {...register('name', {
                  required: 'Please fill out the rest fields. This is mandatory.',
                  pattern: {
                    value: /^[\s\S]{1,255}$/,
                    message: "You can't write more than 255 characters",
                  },
                })}
                error={!!errors.name}
                helperText={errors.name?.message}
              />
              <Select
                {...register('section_id')}
                size="medium"
                className={styles.select}
                sx={{
                  '.MuiOutlinedInput-notchedOutline': { border: '1px solid lightgrey' },
                  '.MuiSelect-select': { padding: 0 },
                }}
                displayEmpty
                value={watch('section_id')}
                defaultValue={activeSection?.id}
                renderValue={(selected) => {
                  const selectedItem = sections.find((item) => item.id === selected);
                  return selectedItem ? selectedItem.name : 'Select section';
                }}
              >
                <MenuItem disabled>Select section </MenuItem>
                {sections.map((item) => (
                  <MenuItem value={item.id} key={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
              <Select
                {...register('position')}
                size="medium"
                className={styles.select}
                sx={{
                  '.MuiOutlinedInput-notchedOutline': { border: '1px solid lightgrey' },
                  '.MuiSelect-select': { padding: 0 },
                }}
                displayEmpty
                value={watch('position')}
                renderValue={(selected) => {
                  if (!selected) {
                    return <p>Position in the block</p>;
                  }
                  return selected;
                }}
              >
                <MenuItem disabled>Position in the block</MenuItem>
                {POSITIONS.map((item) => (
                  <MenuItem value={item} key={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>

              <TextField
                {...register('keywords', {
                  pattern: {
                    value: /^[\s\S]{1,3000}$/,
                    message: "You can't write more than 3000 characters",
                  },
                })}
                className={styles.textarea}
                label="Keywords"
                multiline
                rows={4}
                inputProps={{ style: { resize: 'vertical' } }}
                error={!!errors.keywords}
                helperText={errors.keywords?.message}
              />
            </Box>
            <Divider
              orientation={!isMobile ? 'vertical' : 'horizontal'}
              aria-hidden="true"
              flexItem
            />
            <Box className={styles.inputsBlock}>
              <Box className={styles.titleWithBtn}>
                <Box className={styles.title}>Preview</Box>
                <Box className={styles.addDocBtn} {...getPreviewRootProps()}>
                  <input {...getPreviewInputProps()} />
                  <AddIcon style={{ width: 24, height: 24 }} />
                  <Box>Add File</Box>
                </Box>
              </Box>
              {errors.preview?.message && (
                <Typography className={styles.errorMessage}>{errors.preview?.message}</Typography>
              )}
              {previewFile.image && (
                <Box
                  key={previewFile.image}
                  className={styles.image}
                  sx={{
                    border: `1.8px solid ${previewFile.error ? '#D32F2F' : 'transparent'}`,
                  }}
                >
                  <Image src={previewFile.image} />
                  {previewFile.progress < 100 && (
                    <Box className={styles.imageLoader}>
                      {previewFile.error ? (
                        <Box className={styles.reloadBtn}>
                          <ReloadIcon width={24} height={24} />
                        </Box>
                      ) : (
                        <LinearProgress
                          className={styles.lineProgress}
                          value={previewFile.progress}
                          variant="determinate"
                        />
                      )}
                    </Box>
                  )}
                  <Box className={styles.deleteIcon} onClick={() => setPreviewFile({})}>
                    <TrashIcon width={24} height={24} />
                  </Box>
                </Box>
              )}
              <Box className={styles.titleWithBtn}>
                <Box className={styles.title}>Cover</Box>
                <Box className={styles.addDocBtn} {...getCoverRootProps()}>
                  <input {...getCoverInputProps()} />
                  <AddIcon style={{ width: 24, height: 24 }} />
                  <Box>Add File</Box>
                </Box>
              </Box>
              {coverFile.image && (
                <Box
                  key={coverFile.image}
                  className={styles.image}
                  sx={{
                    border: `1.8px solid ${coverFile.error ? '#D32F2F' : 'transparent'}`,
                  }}
                >
                  <Image src={coverFile.image} />
                  {coverFile.progress < 100 && (
                    <Box className={styles.imageLoader}>
                      {coverFile.error ? (
                        <Box className={styles.reloadBtn}>
                          <ReloadIcon width={24} height={24} />
                        </Box>
                      ) : (
                        <LinearProgress
                          className={styles.lineProgress}
                          value={coverFile.progress}
                          variant="determinate"
                        />
                      )}
                    </Box>
                  )}
                  <Box className={styles.deleteIcon}>
                    <TrashIcon width={24} height={24} />
                  </Box>
                </Box>
              )}

              <Box className={styles.titleWithBtn}>
                <Box className={styles.title}>File</Box>
                <Box className={styles.addDocBtn} onClick={() => setAddLink(true)}>
                  <AddIcon style={{ width: 24, height: 24 }} />
                  <Box>Add File/Link</Box>
                </Box>
              </Box>
              {addLink && (
                <Box className={styles.linkAndFileBlock}>
                  <MuiTextField
                    label="Add link"
                    {...register('link', {
                      pattern: {
                        value:
                          /^(https?:\/\/)?([a-zA-Z0-9.-]+)(\.[a-zA-Z]{2,})(\/[a-zA-Z0-9#-._~:\/?@!$&'()*+,;=]*)?$/,
                        message: 'Invalid url',
                      },
                    })}
                    error={!!errors.link}
                    helperText={errors.link?.message}
                  />
                  {!fileUrl && <DropZone onDropped={onDropped} />}
                </Box>
              )}
              {file && fileUrl && (
                <Box onClick={() => openPdfFile()} className={styles.pdfFile}>
                  <Typography className={styles.fileName}>{file.name}</Typography>
                  <Typography className={styles.fileSize}>{file.size}</Typography>
                  <Typography className={styles.fileExtension}>{file.extension}</Typography>
                  <TrashIcon width={24} height={24} onClick={deleteFile} />
                </Box>
              )}
              {errors.file?.message && (
                <Typography className={styles.errorMessage}>{errors.file?.message}</Typography>
              )}
            </Box>
          </Box>
          <Button type="primary" isSubmit>
            {presentationActionOrId && presentationActionOrId === 'add' ? 'Publish' : 'Save'}
          </Button>
        </form>
      )}
    </>
  );
};
export default AddOrEditPresentation;
