import React from 'react';
import styles from './NavbarUnauthDrawer.module.scss';
import { Drawer } from 'tt-ui-lib/core';
import { Link } from 'react-router-dom';
import { calculateLinks } from 'modules/tt-left-menu';
import { clsx } from 'clsx';

const NavbarUnauthDrawer = ({ open, setOpen, redirectDomain, mode = 'main', qaTTUrl }) => {
  const onClose = () => {
    setOpen(false);
  };

  const redirectQa = (url) => `${qaTTUrl}${url}`;

  const topProps = [
    { href: redirectDomain ? `${redirectDomain}/tutorials` : '/tutorials', name: 'Tutorials' },
    { href: redirectQa('/'), name: 'Q&A' },
    { href: redirectDomain ? `${redirectDomain}/menu` : '/menu', name: 'Menu' },
  ];

  const otherProps = calculateLinks(mode);

  return (
    <Drawer placement="right" open={open} onClose={onClose} width={295}>
      <div className={styles.modalDialogBlock} onClick={(event) => event.stopPropagation()}>
        <div>
          {topProps.map((link) => (
            // eslint-disable-next-line react/jsx-key
            <div className={styles.linksRow}>
              <Link
                color="inherit"
                underline="none"
                to={link.href}
                className={styles.links}
                onClick={onClose}
              >
                {link.name}
              </Link>
            </div>
          ))}
        </div>

        <div className={styles.divider} />

        <div>
          {otherProps.avatarMenu.map((link) => (
            <div key={link.name} className={styles.linksRow}>
              <Link
                to={link.href}
                color="inherit"
                underline="none"
                className={clsx(styles.links, link?.disabled ? styles.disabledLink : '')}
              >
                {link.name}
              </Link>
            </div>
          ))}
          {otherProps.mainMenu.map((link) => (
            <div key={link.name} className={styles.linksRow}>
              <Link
                to={link.href}
                color="inherit"
                underline="none"
                className={clsx(styles.links, link?.disabled ? styles.disabledLink : '')}
              >
                {link.name}
              </Link>
            </div>
          ))}
        </div>
      </div>
    </Drawer>
  );
};

export default NavbarUnauthDrawer;
