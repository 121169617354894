import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { CARBON_GET_LIST } from 'api';
import { SearchIcon } from 'tt-ui-lib/icons';
import { Input, Select, openNotification, HelpTooltip } from 'tt-ui-lib/core';
import { useNavigate } from 'react-router-dom';
import { AssetsTab } from '../../../../modules/tt-tokenized-assets/index';
import styles from '../../Decarbonizator.module.scss';

const { REACT_APP_MARKET } = process.env;

const CafeeTab = () => {
  const navigate = useNavigate();

  const [getCarbonCreditsList] = useLazyQuery(CARBON_GET_LIST, {
    fetchPolicy: 'network-only',
  });

  // const [first, setFirst] = useState('old');
  const [CaFFees, setCaFFees] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchString, setSearchString] = useState('');

  const getTableData = () => {
    setLoading(true);

    getCarbonCreditsList()
      .then((response) => {
        setCaFFees(response?.data?.getAllUserCarbonCredit?.map((item) => ({ ...item })) || []);
      })
      .catch(() => {
        openNotification({
          type: 'error',
          message: 'Error while getting credit data',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onAssetsRowClick = (row) => {
    navigate(`/dashboard/decarbonizator/certificate/${row.id}`);
  };

  useEffect(() => {
    getTableData();
  }, []);

  return (
    <div className={styles.caffeeTab}>
      <div className={styles.filters}>
        <Input
          placeholder="Search by Certificate ID"
          className={styles.searchFilter}
          prefix={<SearchIcon style={{ width: 20, height: 20 }} />}
          value={searchString}
          onChange={(e) => setSearchString(e.target.value)}
        />

        <Select
          label="Date"
          type="default"
          name="date"
          value={null}
          className={styles.topSelect}
          options={[{ value: '1', label: 'Date' }]}
        />
      </div>

      {CaFFees.length > 0 || loading ? (
        <AssetsTab
          data={
            searchString
              ? CaFFees.filter((item) => `${item.credit_id}`.includes(searchString))
              : CaFFees
          }
          onRowClick={onAssetsRowClick}
          isLoading={loading}
          description="CaFFee is the ultimate solution for the carbon credit tokenization and customer loyalty that allows you to offset the tiniest carbon footprint of your product or service. Not only does CaFFee navigate you through the carbon credit tokenization by dividing the carbon credit into numerous tiny fractions but allows you to reward your end-customer with the offset certificate relating to their purchased product/service recognizing and celebrating their efforts towards a sustainable world."
        />
      ) : (
        <div className={styles.container}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span className={`${styles.h1} ${styles.header}`} style={{ marginBottom: 0 }}>
              CAFFEE
            </span>
            <HelpTooltip tooltipText="All your digitized carbon credits and certificates you can find in this section. Clicking on the certificate, you get access to detailed information and more options." />
          </div>

          <div className={styles.emptyDesc}>
            Find all your digitized carbon credits and certificates here. <br /> Ready to start?
            Visit our{' '}
            <a href={`${REACT_APP_MARKET}/dashboard/marketplace/list/product?activeTab=carbon`}>
              Carbon Credit Marketplace
            </a>{' '}
            to purchase credits.
          </div>
        </div>
      )}
    </div>
  );
};

export default CafeeTab;
