import React, { useState } from 'react';
import { HelpTooltip, Button } from 'tt-ui-lib/core';
import styles from '../../Decarbonizator.module.scss';

const { REACT_APP_MARKET } = process.env;

const FcemTab = () => {
  const [mode, setMode] = useState(false);

  return (
    <div className={styles.container} style={{ marginTop: 2 }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span className={`${styles.h1} ${styles.header}`} style={{ marginBottom: 0 }}>
          FCEM
        </span>
        <HelpTooltip tooltipText="To get access to your FCEM coins, you need to use the MetaMask extension and request this asset to your wallet." />
      </div>

      {mode ? (
        <>
          <div className={styles.emptyDesc}>
            Below you see the balance of your FCEM coins that have been unlocked from deposit
            following the offset of the carbon credit. FCEM is a native coin of FCEchain, the
            cornerstone of the TransparenTerra ecosystem. To celebrate the efforts of the early
            adopters and contributors to the FCEchain project - holders of FCEM coins - towards a
            cleaner and greener planet we reward them with certain benefits for the purchase of
            carbon credits. For more information, check our{' '}
            <a href={`${REACT_APP_MARKET}/dashboard/marketplace/list/product?activeTab=carbon`}>
              FCEM Deposit Program
            </a>
            .
          </div>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div>Balance: -</div>
            <Button disabled>Claim</Button>
          </div>
        </>
      ) : (
        <div className={styles.emptyDesc}>
          Below is the current balance of your FCEM coins. If you wish, you may transfer them to
          your MetaMask wallet at any time by clicking on “Claim”.
        </div>
      )}
    </div>
  );
};

export default FcemTab;
